<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-if="agency"
              v-slot="{ handleSubmit, invalid, touched }"
              ref="updateForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(createAgency)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="agency.first_name"
                      name="first_name"
                      rules="required"
                      label="First Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="agency.last_name"
                      name="last_name"
                      rules="required"
                      label="Last Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="agency.name"
                      name="company_name"
                      rules="required"
                      label="Company Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="agency.email"
                      name="email"
                      rules="required|email"
                      label="Email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mt-2 mb-4 pt-2">
                      <span class="pr-2" style="font-weight:600;">OFF</span>
                      <b-form-checkbox
                        v-model="agency.free_fee"
                        name="check-button"
                        switch
                        inline
                      >
                        ON - Free fee
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingCreate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingCreate" small />
                    <span v-else>Create</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agency: {
        name: '',
        email: '',
        free_fee: false,
      },
      loadingCreate: false,
    }
  },

  computed: {
    whitelabelDomain() {
      return this.$store.getters['app/whitelabelDomain']
    },
  },

  methods: {
    createAgency() {
      this.loadingCreate = true

      this.$store
        .dispatch('agency/create', this.agency)
        .then(() => {
          this.$router.push({ name: 'admin.agencies.index' })
          this.loadingCreate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },
  },
}
</script>
